import React, { Fragment, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faPrint } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { printPaymentReceipt } from '../Templates/PrintableReceipt'
import { formatMoney } from '../../utils/string.utils'
import { formasDePagamento, statusParcelas } from '../../constants/common.constants'
import FinanceiroService from '../../services/financeiro.service'
import OrtodontiaService from '../../services/ortodontia.service'

export default function PagamentosRealizados({
  setIsLoading,
  pagamentosRealizados,
  paciente,
  getPagamentosRealizados,
}) {
  const [isOpen, setIsOpen] = useState('')

  const handlePrint = async (pagamento) => {
    setIsLoading(true)
    printPaymentReceipt({
      ...pagamento,
      nomeContratante: paciente.nome,
      nomeColaborador: typeof pagamento.nomeColaborador !== "undefined" && pagamento.nomeColaborador !== null ? pagamento.nomeColaborador : "",
      parcelasPayload: pagamento.parcelas,
      formaPagamento: !!pagamento.formaPagamento ? pagamento.formaPagamento : formasDePagamento.DINHEIRO.label,
    })
    if (pagamento.pagamentoOrtodontia) {
      await OrtodontiaService.marcarImpresso(pagamento.id)
      await getPagamentosRealizados()
    } else {
      await FinanceiroService.marcarImpresso(pagamento.id)
      await getPagamentosRealizados()
    }
    setIsLoading(false)
  }

  const handleOpen = (codigoValidacao) => {
    isOpen === codigoValidacao ? setIsOpen('') : setIsOpen(codigoValidacao)
  }

  const getStatusParcelaLabel = (parcela) => {
    const statusObj = Object.values(statusParcelas).find((status) => status.value === parcela.status)
    return statusObj ? statusObj.label : '--'
  }

  const groupedPayments = pagamentosRealizados.reduce((acc, pagamento) => {
    const validationKey = pagamento.codigoValidacao || 'Sem validação'

    if (!acc[validationKey])
      acc[validationKey] = []

    acc[validationKey].push(pagamento)
    return acc
  }, {})

  return (
    <Fragment>
      {pagamentosRealizados && pagamentosRealizados.length > 0 ? (
        <Table className='mt-3' bordered>
          <thead>
            <tr className='text-center'>
              <th width='150px'>Data</th>
              <th width='150px'>Validação</th>
              <th width='150px'>Plano</th>
              <th>Colaborador</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedPayments).map((codigoValidacao, index) => {
              const expand = codigoValidacao === isOpen
              const grupoPorForma = groupedPayments[codigoValidacao]
              const primeiroPagamento = grupoPorForma?.[0]

              return (<Fragment key={codigoValidacao}>
                <tr className="text-center bg-light">
                  <td>{moment(primeiroPagamento.dataPagamento).format('DD/MM/YYYY - HH:mm')}</td>
                  <td>{codigoValidacao}</td>
                  <td>{primeiroPagamento.numeroPlano}</td>
                  <td>{primeiroPagamento.nomeColaborador}</td>
                </tr>
                <tr key={index + 'tr2'}>
                  <td colSpan='4'>
                    <FontAwesomeIcon
                      onClick={() => handleOpen(codigoValidacao)}
                      className='ml-auto d-block mb-2 mt-2 text-secondary'
                      style={{ marginRight: '32px', cursor: 'pointer' }}
                      icon={expand ? faChevronUp : faChevronDown}
                    />
                    <div className={expand ? 'd-block' : 'd-none'}>
                      {grupoPorForma.map((parcela, index) => (

                        <Table>
                          <thead>
                            <tr className='text-center' style={{ backgroundColor: 'white' }}>
                              <th>Forma de Pagamento</th>
                              <th>Valor Pago</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr key={index} className='text-center' style={{ backgroundColor: 'white' }}>
                                <td>{parcela.formaPagamento ?? '-'}</td>
                                <td>{parcela.valorPago ? formatMoney(parcela.valorPago) : 'Pagamento cancelado'}</td>
                                <td>
                                  {!!parcela.valorPago && (
                                    <OverlayTrigger
                                      trigger='hover'
                                      placement='top'
                                      overlay={<Tooltip>Imprimir Comprovante</Tooltip>}
                                    >
                                      <Button onClick={() => handlePrint(parcela)} variant='primary' size='sm'>
                                        <FontAwesomeIcon icon={faPrint} />
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  <Table>
                                    <thead>
                                      <tr className='text-center' style={{ backgroundColor: 'white' }}>
                                        <th>Vencimento</th>
                                        <th>Parcela</th>
                                        <th>Desconto</th>
                                        <th>Juros</th>
                                        <th>Multa</th>
                                        <th>Status</th>
                                        <th>Valor Pago</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {parcela.parcelas.map((parcela, index) => (
                                        <tr key={index} className='text-center' style={{ backgroundColor: 'white' }}>
                                          <td>{moment(parcela.dataVencimento).format('DD/MM/YYYY')}</td>
                                          <td>{parcela.parcela}</td>
                                          <td>{parcela.desconto > 0 ? formatMoney(parcela.desconto) : '-'}</td>
                                          <td>{parcela.juros > 0 ? formatMoney(parcela.juros) : '-'}</td>
                                          <td>{parcela.multa > 0 ? formatMoney(parcela.multa) : '-'}</td>
                                          <td>{getStatusParcelaLabel(parcela)}</td>
                                          <td>{parcela.valorPago ? formatMoney(parcela.valorPago) : 'Pagamento cancelado'}</td>
                                        </tr>

                                      ))}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            </>
                          </tbody>
                        </Table>
                      ))}

                    </div>
                  </td>
                </tr>
              </Fragment>)
            })}
          </tbody>
        </Table>
      ) : (
        <p>Nenhum pagamento realizado.</p>
      )}
    </Fragment>
  )
}
