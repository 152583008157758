import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Badge, Row, Col, Button, Table, OverlayTrigger, Tooltip, ButtonGroup, InputGroup, FormControl } from 'react-bootstrap'
import Moment from 'moment'
import formatCpf from '@brazilian-utils/format-cpf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPowerOff, faEye, faSearch, faChevronDown, faChevronUp, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner';

import MenuContext from '../../contexts/MenuContexts'
import PageLoading from '../Common/PageLoading'
import PacientesService from '../../services/paciente.service'
import PermissoesConstants from '../../constants/permissoes.constants'
import { tiposTelefone } from '../../constants/telefones.constants'

export default function PacienteList(props)
{
  let acoesPermitidas = {}
  const [dadosPacientes, setDadosPacientes] = useState([])
  const [showInativos, setShowInativos] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPagina, setIsLoadingPagina] = useState(false)
  const [pacienteFilter, setPacienteFilter] = useState('')
  const [proximaPagina, setProximaPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [isOpenHistoricoTelefone, setIsOpenHistoricoTelefone] = useState('')
  const pagina = useRef(1)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
  }, [])

  useEffect(() => {
    fetchPacientes(proximaPagina)
  }, [proximaPagina])

  useEffect(() => {
    fetchPrimeiraPaginaPacientes()
  }, [showInativos])

  const fetchPrimeiraPaginaPacientes = async () => {
    try {
      setIsLoading(true)
      const filtros = [
        {
          campo: 'pacienteNome',
          valor: pacienteFilter
        },
        {
          campo: 'pacienteAtivo',
          valor: !showInativos
        }
      ]

      const { data } = await PacientesService.getPacientesByNome(filtros, 0, 20)
      setTotalPaginas(data.totalPaginas)
      setDadosPacientes(data.dados)
      
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const fetchPacientes = async (p) => {
    try {
      if(p > totalPaginas)
        return

      setIsLoadingPagina(true)

      const filtros = [
        {
          campo: 'pacienteNome',
          valor: pacienteFilter
        },
        {
          campo: 'pacienteAtivo',
          valor: !showInativos
        }
      ]

      const { data } = await PacientesService.getPacientesByNome(filtros, p, 20)

      const concatPacientes = [...dadosPacientes]
      
      if(dadosPacientes && p != 1){
        data.dados.map((d) => concatPacientes.push(d))
        setDadosPacientes(concatPacientes)
      }
      else{
        setDadosPacientes(data.dados)
      }
      setIsLoadingPagina(false)
    } catch (e) {
      setIsLoadingPagina(false)
    }
  }

  const handleFilterPaciente = (e) => {
    setPacienteFilter(e.target.value)
  }

  const toggleInativos = (showInativos) => {
    setProximaPagina(1)
    setShowInativos(showInativos)
  }

  const editPaciente = (currentId) => {
    props.editarPaciente(currentId)
    props.history.push(`/pacientes/${currentId}`)
  }

  const desativar = async (pacienteId, ativar) => {
    try {
      await PacientesService.desativarPaciente(pacienteId, ativar)
      
      dadosPacientes.find(x => x.id == pacienteId).ativo = ativar
      setDadosPacientes(dadosPacientes.filter(x => x.ativo == !showInativos))

    } catch (e) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleScroll = (e) => {
    if(e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      let p = pagina.current + 1
      
      pagina.current = p

      setProximaPagina(pagina.current)
    }
  }

  const handleOnPress = (e) => {
    if (e.key === 'Enter') 
    { 
      fetchPrimeiraPaginaPacientes()
    }
  }

  const handleOpen = (item) => {
    if(isOpenHistoricoTelefone === item)
      setIsOpenHistoricoTelefone('')
    else
    setIsOpenHistoricoTelefone(item)
  }  

  const expandModal = (i) => {
    return i === isOpenHistoricoTelefone
  }

  const renderLoading = () => <PageLoading />
  
  const renderLista = () => (
    <Fragment>
      {dadosPacientes && dadosPacientes.length > 0 ? (
        <div>
          {isLoadingPagina &&
            <div
                style={{
                  width: '80%',
                  height: '80%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'fixed'
                }}
              >
                <Loader type="ThreeDots" color="#0881c7 " height="100" width="100" />
            </div>  
          }
          <Table bordered hover id='table' style={{backgroundColor: 'white'}}>
            <thead>
              <tr>
              
                <th style={{borderLeft: 'none', borderRight: 'none'}}>Nome</th>
                <th style={{borderLeft: 'none', borderRight: 'none'}} className='text-center' width='200px'>
                  CPF
                </th>
                <th style={{borderLeft: 'none', borderRight: 'none'}} className='text-center' width='200px'>Telefone</th>                
                <th style={{borderLeft: 'none', borderRight: 'none'}} className='text-center' width='200px'>Whatsapp</th>
                <th style={{borderLeft: 'none', borderRight: 'none'}} className='text-center' width='300px'>Email</th>
                {Object.keys(acoesPermitidas).length > 0 && (
                  <th style={{borderLeft: 'none', borderRight: 'none'}} className='text-center' width='100px'></th>
                )}
              </tr>
            </thead>
            <tbody>
              {dadosPacientes.map((paciente, index) => (
                <Fragment key={index}>
                <tr key={index}>
                
                  <td style={{borderLeft: 'none', borderRight: 'none'}}>{paciente.nome}</td>
                  <td style={{borderLeft: 'none', borderRight: 'none'}} className='text-center'>{formatCpf(paciente.cpf)}</td>
                  <td style={{borderLeft: 'none', borderRight: 'none'}}  className='text-center'>
                    <span>{paciente.telefones.find(x => x.principal)?.numero || '-'} </span>
                    <> </> <> </>
                    {paciente.historicoTelefones && paciente.historicoTelefones.length > 0 && (
                    <Badge pill variant="primary" style={{padding: '5px 10px', marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleOpen(index)}> + {paciente.historicoTelefones.length}  <FontAwesomeIcon style={{marginLeft:'8px'}} icon={isOpenHistoricoTelefone === index ? faChevronUp : faChevronDown} /></Badge>
                    )}
                  </td>     
                  <td style={{borderLeft: 'none', borderRight: 'none'}} className='text-center'>{paciente.telefones.find(x => x.tipoTelefone == 3)?.numero || '-'}</td>
                  <td style={{borderLeft: 'none', borderRight: 'none'}} className='text-center'>{paciente.emails && paciente.emails.length > 0 ? paciente.emails[0].endereco : '-'}</td>

                  {Object.keys(acoesPermitidas).length > 0 && (
                    <td style={{borderLeft: 'none', borderRight: 'none'}} className='text-center'>
                      {acoesPermitidas[PermissoesConstants.EDITAR] && (
                        <OverlayTrigger trigger='hover' placement='top' overlay={<Tooltip>Editar Paciente</Tooltip>}>
                          <Button onClick={() => editPaciente(paciente.id)} variant='primary' size='sm' style={{marginRight: '5px'}}>
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Button>
                        </OverlayTrigger>
                      )}
                      {acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] && (
                        <OverlayTrigger
                          trigger='hover'
                          placement='top'
                          overlay={<Tooltip>{paciente.ativo ? 'Desativar paciente' : 'Ativar paciente'}</Tooltip>}
                        >
                          <Button
                            onClick={() => desativar(paciente.id, !paciente.ativo)}
                            variant={paciente.ativo ? 'success' : 'danger'}
                            size='sm'  style={{marginRight: '5px'}}
                          >
                            <FontAwesomeIcon icon={faPowerOff} />
                          </Button>
                        </OverlayTrigger>
                      )}
                      {!acoesPermitidas[PermissoesConstants.ATIVAR_INATIVAR] &&
                        !acoesPermitidas[PermissoesConstants.EDITAR] &&
                        acoesPermitidas[PermissoesConstants.DETALHAR] && (
                          <Button
                            title={'Detalhes'}
                            onClick={() => editPaciente(paciente.id)}
                            variant='primary'
                            size='sm'
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                      )}
                    </td>)}
                </tr>
                {paciente.historicoTelefones.map((x) => {
                  return (
                    <tr className={expandModal(index) ? null : 'd-none'}>
                      
                      <td style={{ border: 'none' }}>{' '}</td>
                      <td style={{ border: 'none' }}>{' '}</td>
                      <td style={{ border: 'none' }} className='text-center'>{x.numero}</td>
                      <td style={{ border: 'none' }}>{' '}</td>
                      <td style={{ border: 'none' }}>{' '}</td>
                      {Object.keys(acoesPermitidas).length > 0 && (
                        <td width='120px' style={{ border: 'none' }} ></td>
                      )}
                    </tr>
                  )
                })}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div>Nenhum paciente encontrado.</div>
      )}
    </Fragment>
  )

  return (
    <MenuContext.Consumer>
      {(acoes) => {
        acoes.map((a) => {
          acoesPermitidas[`${a.nome}`] = a
          return true
        })
      return (
        <div>
          <Row className='mb-4'>
            <Col>
              <h1 className='h3 m-0 text-secondary font-weight-bold'>Pacientes</h1>
            </Col>
            <Col className='d-flex justify-content-between'>

              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Filtrar por nome..."
                  aria-label="Filtrar por nome..."
                  aria-describedby="Filtrar por nome..."
                  onChange={handleFilterPaciente}
                  value={pacienteFilter}
                  onKeyPress={handleOnPress}
                />
                <InputGroup.Append>
                  <Button variant="outline-primary" onClick={fetchPrimeiraPaginaPacientes} ><FontAwesomeIcon icon={faSearch}/></Button>
                </InputGroup.Append>
              </InputGroup>
              <ButtonGroup aria-label='Basic example' className='ml-2' style={{height:'35px'}}>
                <Button
                  onClick={() => toggleInativos(false)}
                  variant={showInativos ? 'outline-primary' : 'primary'}
                >
                  Ativos
                </Button>
                <Button
                  onClick={() => toggleInativos(true)}
                  variant={!showInativos ? 'outline-primary' : 'primary'}
                >
                  Inativos
                </Button>
              </ButtonGroup>
            </Col>
            <Col>
              {acoesPermitidas[PermissoesConstants.ADICIONAR] && (
                <Button onClick={() =>props.history.push(`/pacientes/cadastrar`) } className='float-right'>
                  Cadastrar paciente
                </Button>
              )}
            </Col>
          </Row>
          {isLoading ? renderLoading() : renderLista(dadosPacientes, acoesPermitidas)}
        </div>)
      }}
    </MenuContext.Consumer>
  )
}
