import jsPDF from 'jspdf'
import moment from 'moment'
import { formatMoney } from '../../../utils/string.utils'

const maxLineLength = 60
const pageWidth = 80 * 2.5
const pageHeight = 420 * 3
const rowHeight = 5

const addParcelasToReceipt = (doc, yOffset, parcelas) => {
  parcelas.forEach(
    ({ parcela, dataVencimento, valor, valorOriginal, multa, juros, desconto, procedimentoProteticoNome }, index) => {
      if (index) {
        yOffset += rowHeight * 1.5
      } else {
        yOffset += rowHeight
      }

      if (procedimentoProteticoNome) {
        const nomeProcedimento = doc.splitTextToSize(procedimentoProteticoNome, maxLineLength - 7)
        doc.text('Nome:', 2, yOffset)
        doc.setFontType('bold')
        doc.text(nomeProcedimento, 13, yOffset)
        doc.setFontType('normal')
        yOffset += rowHeight + (nomeProcedimento.length > 1 ? nomeProcedimento.length : 0)
      }

      doc.text(`Parcela:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(parcela, 14, yOffset)
      doc.setFontType('normal')

      yOffset += rowHeight
      doc.text(`Vencimento:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(moment(dataVencimento).format('DD/MM/YYYY'), 19, yOffset)
      doc.setFontType('normal')

      yOffset += rowHeight
      doc.text(`Valor Parcela:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(formatMoney(valor ? valor : valorOriginal), 21, yOffset)
      doc.setFontType('normal')

      yOffset += rowHeight
      doc.text(`Multa:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(formatMoney(multa), 12, yOffset)
      doc.setFontType('normal')

      yOffset += rowHeight
      doc.text(`Juros:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(formatMoney(juros), 11, yOffset)
      doc.setFontType('normal')

      yOffset += rowHeight
      doc.text(`Desconto:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(formatMoney(desconto), 17, yOffset)
      doc.setFontType('normal')
    }
  )

  return yOffset
}

export const printPaymentReceipt = async ({
  nomeContratante,
  nomeColaborador,
  reimpressao,
  codigoValidacao,
  dataPagamento,
  parcelasPayload,
  valorPago,
  formaPagamento,
  formasPagamentoSelecionadas,
}) => {
  const parcelasProteticas = []
  const parcelasNaoProteticas = []
  parcelasPayload.forEach((parcela) => {
    if (parcela.procedimentoProteticoNome) {
      parcelasProteticas.push({ ...parcela })
    } else {
      parcelasNaoProteticas.push({ ...parcela })
    }
  })

  const { numeroPlano } = parcelasPayload[0]

  let nomeComprovante = 'Comprovante de Pagamento Clínica'
  if (parcelasNaoProteticas.length && parcelasProteticas.length) {
    nomeComprovante = 'Comprovante de Pagamento Clínica/Protético'
  } else if (parcelasProteticas.length) {
    nomeComprovante = 'Comprovante de Pagamento Protético'
  }

  const doc = new jsPDF('p', 'mm', [pageHeight, pageWidth])
  nomeComprovante = doc.splitTextToSize(nomeComprovante, maxLineLength - 2)

  let yOffset = rowHeight
  doc.setFontSize(10)
  doc.setFontType('bold')
  doc.text(nomeComprovante, 35, yOffset, 'center')
  doc.setFontType('normal')
  doc.setFontSize(8)
  yOffset += rowHeight

  if (reimpressao) {
    yOffset += rowHeight * 2
    doc.setFontType('bold')
    doc.text(`REIMPRESSÃO`, 35, yOffset, 'center')
    doc.setFontType('normal')
  }
  yOffset += rowHeight * 2
  doc.text(`Validação:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(codigoValidacao, 17, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  doc.text(`Data:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(moment(dataPagamento).format('DD/MM/YYYY  -  HH:mm'), 10, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  doc.text(`Número Plano:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(numeroPlano, 21, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  let clientePacienteText = doc.splitTextToSize(nomeContratante.toUpperCase(), maxLineLength - 7)
  doc.text(`Cliente:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(clientePacienteText, 13, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight + (clientePacienteText.length > 1 ? clientePacienteText.length : 0)
  clientePacienteText = doc.splitTextToSize(nomeColaborador.toUpperCase(), maxLineLength - 7)
  doc.text(`Operador(a):`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(clientePacienteText, 19, yOffset)
  doc.setFontType('normal')

  if (parcelasNaoProteticas.length) {
    yOffset += rowHeight * 2
    doc.setFontSize(10)
    doc.setFontType('bold')
    doc.text('Parcelas Clínica', 35, yOffset, 'center')
    doc.setFontType('normal')
    doc.setFontSize(8)
    yOffset = addParcelasToReceipt(doc, yOffset, parcelasNaoProteticas)
  }

  if (parcelasProteticas.length) {
    yOffset += rowHeight * 2
    doc.setFontSize(10)
    doc.setFontType('bold')
    doc.text('Parcelas Protético', 35, yOffset, 'center')
    doc.setFontType('normal')
    doc.setFontSize(8)
    yOffset = addParcelasToReceipt(doc, yOffset, parcelasProteticas)
  }

  yOffset += rowHeight
  doc.line(0, yOffset, 100, yOffset)

  if(formasPagamentoSelecionadas){
    for(const forma of formasPagamentoSelecionadas) {
      yOffset += rowHeight
      doc.text(`Total Pago:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(formatMoney(forma.valor, true), 18, yOffset)
      doc.setFontType('normal')

      yOffset += rowHeight
      doc.text(`Forma de Pagamento:`, 2, yOffset)
      doc.setFontType('bold')
      doc.text(forma?.formaPagamento?.label, 32, yOffset)
      doc.setFontType('normal')

      yOffset += rowHeight
      doc.line(0, yOffset, 100, yOffset)
    }
  }

 
  window.open(doc.output('bloburl'))
  // doc.save(`agendamento-${nomeContratante}.pdf`)
}

export const printAgendamentoReceipt = async (receipt) => {
  const { data, nomeContratante, nomePaciente, nomeColaborador } = receipt
  const doc = new jsPDF('p', 'mm', [pageHeight, pageWidth])

  let yOffset = rowHeight
  doc.setFontSize(10)
  doc.setFontType('bold')
  doc.text(`LEMBRETE DE CONSULTA:`, 35, yOffset, 'center')
  doc.setFontType('normal')

  yOffset += rowHeight * 2
  doc.setFontSize(8)
  doc.text(`Data:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(moment(data).format('DD/MM/YYYY  -  HH:mm'), 10, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  let clientePacienteText = doc.splitTextToSize(nomeContratante.toUpperCase(), maxLineLength - 7)
  doc.text(`Cliente:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(clientePacienteText, 13, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight + (clientePacienteText.length > 1 ? clientePacienteText.length : 0)
  clientePacienteText = doc.splitTextToSize(nomePaciente.toUpperCase(), maxLineLength - 7)
  doc.text(`Paciente:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(clientePacienteText, 15, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight + (clientePacienteText.length > 1 ? clientePacienteText.length : 0)
  doc.text(`Profissional:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(nomeColaborador.toUpperCase(), 18, yOffset)
  doc.setFontType('normal')

  window.open(doc.output('bloburl'))
  // doc.save(`agendamento-${nomeContratante}.pdf`)
}

export const printParcelasReceipt = async (receipt) => {
  const { numeroPlano, nomeContratante, nomePaciente, parcelas, valorTotal, type, nomeProtese } = receipt
  const doc = new jsPDF('p', 'mm', [pageHeight, pageWidth])
  doc.setFontSize(10)

  let yOffset = rowHeight
  const parcelamentoText = `PARCELAMENTO ${
    type === 'aparelho'
      ? 'DE APARELHO'
      : type === 'suprimento'
      ? 'DE SUPRIMENTO'
      : type === 'protese'
      ? 'DE PRÓTESE'
      : 'DO TRATAMENTO'
  }`
  doc.setFontType('bold')
  doc.text(parcelamentoText, 35, yOffset, 'center')
  doc.setFontType('normal')

  doc.setFontSize(8)
  yOffset += rowHeight * 2
  doc.text(`TRATAMENTO:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(`${numeroPlano}`, 23, yOffset)
  doc.setFontType('normal')
  
  if(type === 'protese'){
    let nomeProteseText = doc.splitTextToSize(nomeProtese.toUpperCase(), maxLineLength - 7)
    yOffset += rowHeight
    doc.text(`PRÓTESE:`, 2, yOffset)
    doc.text(nomeProteseText, 17, yOffset)
    doc.setFontType('normal')
    yOffset += rowHeight + (nomeProteseText.length > 1 ? nomeProteseText.length : 0)
  }
  
  let clientePacienteText = doc.splitTextToSize(nomeContratante.toUpperCase(), maxLineLength - 7)
  yOffset += rowHeight
  doc.text(`CLIENTE:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(clientePacienteText, 16, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight + (clientePacienteText.length > 1 ? clientePacienteText.length : 0)
  clientePacienteText = doc.splitTextToSize(nomePaciente.toUpperCase(), maxLineLength - 7)
  doc.text(`PACIENTE:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(clientePacienteText, 18, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight + (clientePacienteText.length > 1 ? clientePacienteText.length : 0)
  doc.text(`DATA IMPRESSÃO:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(moment(new Date()).format('DD/MM/YYYY  -  HH:mm'), 29, yOffset)

  yOffset += rowHeight * 2
  doc.text('PARCELA(S)', 2, yOffset)
  doc.text('VENCIMENTO', 35, yOffset, 'center')
  doc.text('VALOR', 69, yOffset, 'right')
  doc.setFontType('normal')

  let total = 0
  
  let parcelasSemCanceladas = parcelas.filter(x => x.status != 2)
  let quantParcelas = parcelasSemCanceladas.length
  parcelasSemCanceladas.forEach((parcela) => {
    if(parcela.status != 2) //Todas as parcelas menos as canceladas
    {
      yOffset += rowHeight
      const { numero, dataVencimento, valor } = parcela
      if (numero === 'ENTRADA') {
        doc.text(numero, 2, yOffset)
      } else {
        doc.text(`${numero}/${quantParcelas}`, 2, yOffset)
      }
      doc.text(moment(dataVencimento).format('DD/MM/YYYY'), 35, yOffset, 'center')
      doc.text(formatMoney(valor), 70, yOffset, 'right')
      total = total + parcela.valor
    }
  })

  yOffset += rowHeight * 2
  doc.text('VALOR TOTAL:', 2, yOffset)
  doc.setFontType('bold')
  doc.text(formatMoney(total), 70, yOffset, 'right')
  doc.setFontType('normal')

  window.open(doc.output('bloburl'))
  // doc.save(`parcelamento-${nomeContratante}-${numeroPlano}.pdf`)
}

export const printAutorizacaoReceipt = async (receipt) => {
  const { numeroPlano, razaoSocialProtetico, nomeColaborador, nomeTitular, nomeDependente, valorProtese, nomeProtetico } = receipt
  const doc = new jsPDF('p', 'mm', [pageHeight, pageWidth])
  doc.setFontSize(8)

  let yOffset = rowHeight * 2
  doc.setFontSize(8)
  doc.setFontType('bold')
  doc.text(`AUTORIZAÇÃO DE CONFECÇÃO DE PRÓTESE`, 35, yOffset, 'center')
  doc.setFontType('normal')

  yOffset += rowHeight * 2
  doc.setFontSize(8)
  doc.text(`Tratamento:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(numeroPlano, 18, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  doc.text(`Paciente:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text((nomeDependente ? nomeDependente : nomeTitular).toUpperCase(), 15, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  doc.text(`Profissional:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(nomeColaborador ? nomeColaborador.toUpperCase() : 'N/D', 18, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  doc.text(`Valor da Prótese:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(formatMoney(valorProtese), 25, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  doc.text(`Protético:`, 2, yOffset)
  doc.setFontType('bold')
  doc.text(razaoSocialProtetico.toUpperCase(), 15, yOffset)
  doc.setFontType('normal')

  yOffset += rowHeight
  doc.text(`Item:`, 2, yOffset)
  doc.setFontType('bold')
  const proteticoText = doc.splitTextToSize(nomeProtetico.toUpperCase(), maxLineLength - 7.5)
  doc.text(proteticoText, 9, yOffset)
  doc.setFontType('normal')

  window.open(doc.output('bloburl'))
  // doc.save(`parcelamento-${nomeContratante}-${numeroPlano}.pdf`)
}
