import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, Card, Modal, Button, Spinner, Form } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import 'moment/locale/pt-br'
import Swal from 'sweetalert2'

import AgendamentoService from '../../services/agendamentos.service'
import { printAgendamentoReceipt } from '../Templates/PrintableReceipt/index'
import { formatTitulo } from '../../utils/string.utils'
import {
  statusConstants,
  status,
  TIPO_CONSULTA,
  statusAvaliacao,
  statusAvaliacaoOrtodontica,
} from '../../constants/agendamento.constants'
import pacienteService from '../../services/paciente.service'
import { toast } from 'react-toastify'
import WebcamFaceDetectionBlink from '../WebcamFaceDetection/WebcamFaceDetectionBlink'

const ModalDetalhesAgendamento = (props) => {
  const {
    isOpen,
    handleClose,
    agendamento,
    agendamentos,
    editar,
    editarPaciente,
    iniciarAvaliacao,
    iniciarTratamento,
    concluirAtendimento,
    mostrarSelect,
  } = props

  const [statusAgendamento, setStatusAgendamento] = useState(status[0])
  const [telefonePrincipal, setTelefonePrincipal] = useState('')
  const [verificarTelefone, setVerificarTelefone] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [solicitarFacial, setSolicitarFacial] = useState(false);

  useEffect(() => {
    const { id } = agendamento
    id && setStatusAgendamento(status.find((s) => s.value === props.agendamento.statusAgendamento))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendamento])

  const getParcelasEmAtrasoConfirmValue = async (mensagem) => {
    if (mensagem) {
      const { value } = await Swal.fire({
        title: 'Parcelas em Atraso!',
        text: mensagem,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceitar',
        confirmButtonColor: '#3085d6',
        cancelButtonClass: 'text-primary',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#fff',
      })
      return value
    }
    return true
  }

  const verificarParcelasEmAtraso = async (agendamento) => {
    let devedorConfirmValue = true
    if (agendamento.tipoConsulta === TIPO_CONSULTA.MANUTENCAO.value || agendamento.tipoConsulta === TIPO_CONSULTA.PROCEDIMENTO.value) {
      const { data } = await AgendamentoService.verificarParcelasEmAtraso(agendamento)
      devedorConfirmValue = await redirecionaMensagemAgendamento(data)
    }
    return devedorConfirmValue
  }

  const redirecionaMensagemAgendamento = async (data) => {

    if (data.possuiDuasParcelasEmAtraso) {
      const { value } = await Swal.fire({
        title: 'Atenção!',
        text: data.mensagem,
        type: 'warning',
        cancelButtonColor: '#999999',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Entendi',
      });

      return false;
    }
    else
      return await getParcelasEmAtrasoConfirmValue(data.mensagem);
  }

  const setStatus = async (novoStatus) => {
    if (novoStatus === statusConstants.AGUARDANDO && !verificarTelefone && !!getTelefonesList().find((t) => t.principal)) {
      setSolicitarFacial(true);
      return;
    }

    setIsLoading(true)

    try {
      await AgendamentoService.salvarStatusAgendamento(agendamento.id, novoStatus.value)

      toast('Alteração de status salva com sucesso', { type: toast.TYPE.SUCCESS })
      setStatusAgendamento(novoStatus)

      verificarTelefone && setVerificarTelefone(false)
    } catch (error) { }

    setIsLoading(false)
  }

  const handleCancelarVerificacao = () => {
    setVerificarTelefone(false)
    setTelefonePrincipal('')
  }

  const redirectAvaliacaoOrtodontia = (avaliacaoOrtodontia) => {
    !avaliacaoOrtodontia && props.history.push('/ortodontia/avaliacao/dentista')
    avaliacaoOrtodontia &&
      (avaliacaoOrtodontia.statusAvaliacao === statusAvaliacaoOrtodontica.EXAME_SOLICITADO.value ||
        avaliacaoOrtodontia.statusAvaliacao === statusAvaliacaoOrtodontica.TRATAMENTO_DEFINIDO.value)
      ? props.history.push('/ortodontia/avaliacao/recepcao')
      : props.history.push('/ortodontia/avaliacao/dentista')
  }

  const redirectProcedimentoOrtodontia = () => {
    props.history.push('/ortodontia/plano-tratamento/manutencao')
  }

  const redirectPadraoAvaliacao = () => {
    props.history.push('/avaliacao')
  }

  const redirectPadraoProcedimento = () => {
    props.history.push('/tratamento')
  }

  const handleChangeTelefonePrincipal = (e) => {
    let { value } = e.target
    value = mphone(value)
    setTelefonePrincipal(value)
  }

  const handleKeyUp = async (event) => {
    if (event.key === 'Enter') {
      await confirmarTelefonePrincipal()
    }
  }

  const confirmarTelefonePrincipal = async () => {
    if (!telefonePrincipal) {
      return
    }

    if (telefonePrincipal.length < 13) {
      return
    }

    setIsLoading(true)
    try {
      const parcelasEmAtrasoConfirm = await verificarParcelasEmAtraso(agendamento)

      if (parcelasEmAtrasoConfirm) {
        await pacienteService.salvarTelefonePrincipal(agendamento.pacienteId, telefonePrincipal)
        setStatus(statusConstants.AGUARDANDO)
        setVerificarTelefone(false)
      }
    } catch (error) { }
    setIsLoading(false)
  }

  const mphone = (v) => {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{2})(\d)/g, '($1)$2')
    v = v.replace(/(\d)(\d{4})$/, '$1-$2')
    return v
  }

  const handleIniciarClick = async () => {
    const { pacienteId, especialidade, tipoConsulta, avaliacao } = agendamento

    let agendamentoEmAtendimento = agendamentos.find(a => a.agendamento.statusAgendamento === statusConstants.EM_ATENDIMENTO.value)
    if (agendamentoEmAtendimento) {
      Swal.fire({
        title: 'Finalize o atendimento',
        text: 'Você ainda não finalizou um dos seus atendimentos. Finalize e tente novamente',
        type: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      }).then(() => {
        handleClose()
        return
      })
    }
    else {
      setStatus(status.find((f) => f.label === statusConstants.EM_ATENDIMENTO.label))

      if (tipoConsulta === TIPO_CONSULTA.AVALIACAO.value) {
        iniciarAvaliacao(pacienteId, agendamento, especialidade.anamneseFichaId)
        especialidade.nome === 'Ortodontia' ? redirectAvaliacaoOrtodontia(avaliacao) : redirectPadraoAvaliacao()
      } else {
        iniciarTratamento(agendamento)
        especialidade.nome === 'Ortodontia' ? redirectProcedimentoOrtodontia(avaliacao) : redirectPadraoProcedimento()
      }
    }
  }

  const continuarAtendimento = async () => {
    const { pacienteId, especialidade, tipoConsulta, avaliacao } = agendamento
    if (tipoConsulta === TIPO_CONSULTA.AVALIACAO.value) {
      iniciarAvaliacao(pacienteId, agendamento, especialidade.anamneseFichaId)
      especialidade.nome === 'Ortodontia' ? redirectAvaliacaoOrtodontia(avaliacao) : redirectPadraoAvaliacao()
    } else {
      iniciarTratamento(agendamento)
      especialidade.nome === 'Ortodontia' ? redirectProcedimentoOrtodontia(avaliacao) : redirectPadraoProcedimento()
    }
  }

  const handleEditarPaciente = () => {
    const { pacienteId, nomePaciente, cpfPaciente, id } = agendamento
    editarPaciente(pacienteId, nomePaciente, cpfPaciente, getTelefonesList(), id)
    handleClose()
    props.history.push(`/pacientes/${pacienteId}`)
  }

  const concluir = () => {
    setSolicitarFacial(true);
  }

  const getNomePaciente = () => (agendamento.dependente ? agendamento.dependente.nome : agendamento.nomePaciente)

  const handlePrint = () => {
    printAgendamentoReceipt({
      data: agendamento.data,
      nomeContratante: agendamento.nomePaciente,
      nomePaciente: getNomePaciente(),
      nomeColaborador: agendamento.colaborador.nome,
    })
  }

  const getTelefonesList = () =>
    agendamento.paciente && agendamento.paciente.telefones && agendamento.paciente.telefones.length > 0
      ? agendamento.paciente.telefones
      : agendamento.telefones

  return (
    <>
      {solicitarFacial ?
        <WebcamFaceDetectionBlink
          pacienteId={agendamento.pacienteId}
          dependenteId={agendamento.dependenteId ? agendamento.dependenteId : null}
          cancelWebcamFaceDetection={() => {
            setSolicitarFacial(false);
          }}
          closeWebcamFaceDetection={() => {

            if (agendamento.statusAgendamento === statusConstants.FINALIZADO.value) {
              const { avaliacao, avaliacaoOrtodontia } = agendamento

              concluirAtendimento(agendamento)
              if (avaliacao) {
                props.history.push('/atendimento-financeiro')
              } else {
                redirectAvaliacaoOrtodontia(avaliacaoOrtodontia)
              }

              return;
            }

            setSolicitarFacial(false);
            setVerificarTelefone(true);
          }} />
        :
        <Modal className='modal-agenda' show={isOpen} onHide={handleClose}>
          <Card>
            <Modal.Header className='p-4' closeButton>
              <Modal.Title>Detalhes do Agendamento</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>
              <Row className='text-dark'>
                <Col>
                  <div className='d-flex align-items-center'>
                    {agendamento.nomePaciente && (
                      <div className='d-flex justify-content-center align-items-center text-white bg-secondary rounded-circle text-center abrev-nome '>
                        <span style={{ fontSize: 35 }}>{getNomePaciente().substr(0, 2)}</span>
                      </div>
                    )}
                    <div className='pl-3'>
                      <h4>{agendamento.nomePaciente ? formatTitulo(getNomePaciente()) : ''}</h4>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='my-5 text-dark align-items-center'>
                <Col md='8'>
                  <h5 className='text-primary'>
                    <i className='fa fa-calendar-check mr-3' />
                    {moment(agendamento.data).format('LLLL')}
                  </h5>
                </Col>
                <Col md='4' className='text-dark'>
                  {!verificarTelefone && (
                    <Select
                      placeholder='Defina o status do atendimento'
                      onChange={setStatus}
                      value={statusAgendamento}
                      isDisabled={!mostrarSelect}
                      options={status.filter(
                        (s) =>
                          s.label !== statusConstants.EM_ATENDIMENTO.label && s.label !== statusConstants.FINALIZADO.label
                      )}
                    />
                  )}
                  {isLoading && <Spinner animation='border' variant='primary' size='sm' style={spinnerStyle} />}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-fill justify-content-end'>
              {verificarTelefone ? (
                <Fragment>
                  <Form.Control
                    placeholder='Confirme o telefone'
                    value={telefonePrincipal}
                    onKeyUp={handleKeyUp}
                    onChange={handleChangeTelefonePrincipal}
                    maxLength={14}
                  />
                  <Button onClick={handleCancelarVerificacao} variant='outline-primary'>
                    Cancelar
                  </Button>
                  <Button onClick={confirmarTelefonePrincipal} disabled={telefonePrincipal.length < 13}>
                    Atualizar
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  {agendamento.statusAgendamento !== statusConstants.FINALIZADO.value && (
                    <Button variant='outline-primary' onClick={handlePrint}>
                      Imprimir Agendamento
                    </Button>
                  )}
                  {editarPaciente && (
                    <Button variant='outline-primary' onClick={handleEditarPaciente}>
                      {agendamento.pacienteId ? 'Editar' : 'Cadastrar'} Paciente
                    </Button>
                  )}
                  {editar && agendamento.statusAgendamento !== statusConstants.FINALIZADO.value && (
                    <Button variant='outline-primary' onClick={editar}>
                      Editar Agendamento
                    </Button>
                  )}
                  {statusAgendamento.label === statusConstants.AGUARDANDO.label && !mostrarSelect && (
                    <Button onClick={handleIniciarClick} variant='primary'>
                      Iniciar atendimento
                    </Button>
                  )}
                  {statusAgendamento.label === statusConstants.EM_ATENDIMENTO.label &&
                    agendamento.pacienteId &&
                    !mostrarSelect && (
                      <Button onClick={continuarAtendimento} variant='primary'>
                        Continuar atendimento
                      </Button>
                    )}
                  {editar &&
                    statusAgendamento.label === statusConstants.FINALIZADO.label &&
                    ((agendamento.avaliacao && agendamento.avaliacao.statusAvaliacao === statusAvaliacao.PENDENTE) ||
                      (agendamento.avaliacaoOrtodontia &&
                        (agendamento.avaliacaoOrtodontia.statusAvaliacao ===
                          statusAvaliacaoOrtodontica.EXAME_SOLICITADO.value ||
                          agendamento.avaliacaoOrtodontia.statusAvaliacao ===
                          statusAvaliacaoOrtodontica.TRATAMENTO_DEFINIDO.value))) &&
                    agendamento.tipoConsulta === TIPO_CONSULTA.AVALIACAO.value && (
                      <Button onClick={concluir} variant='primary'>
                        Concluir atendimento
                      </Button>
                    )}
                </Fragment>
              )}
            </Modal.Footer>
          </Card>
        </Modal>
      }
    </>
  )
}

const spinnerStyle = {
  position: 'absolute',
  top: '12px',
  right: '60px',
}

export default ModalDetalhesAgendamento
