export const statusProtese = {
  AGUARDANDO_PAGAMENTO: { label: 'Aguardando Pagamento', value: 0 },
  ENVIADA_CONFECCAO: { label: 'Enviada para Confecção', value: 1 },
  PRONTA: { label: 'Pronta', value: 2 },
  COLOCADA: { label: 'Colocada', value: 3 },
  CANCELADA: { label: 'Cancelada', value: 4 },
  RETORNADO_PARA_CLINICA: { label: 'Retornado para Clínica', value: 5 },
  PAGAMENTO_EFETUADO: { label: 'Pagamento Efetuado', value: 6 },
}

export const cargoConstant = {
  DENTISTA: { label: 'Dentista', value: 0 },
  ASSISTENTE: { label: 'Assistente', value: 1 },
  ATENDENTE: { label: 'Atendente', value: 2 },
  ADMINISTRADOR: { label: 'Administrador', value: 3 },
  PROTETICO: { label: 'Protético', value: 4 },
}

export const tiposAcesso = {
  COLABORADOR: { label: 'Colaborador', value: 0 },
  PROTETICO: { label: 'Protético', value: 1 },
}

export const statusProcedimento = {
  PENDENTE: { value: 0, label: 'Pendente' },
  TRATAMENTO: { value: 1, label: 'Em Tratamento' },
  FINALIZADO: { value: 2, label: 'Finalizado' },
  REABERTO: { value: 3, label: 'Reaberto' },
  CANCELADO: { value: 4, label: 'Cancelado pelo Paciente' },
  CANCELADO_AUTOMATICAMENTE: { value: 5, label: 'Cancelado pelo Sistema' },
  ABANDONO: { value: 6, label: 'Abandono de Tratamento' },
}

export const statusPlano = {
  PENDENTE: { value: 0, label: 'Tratamento Iniciado' },
  TRATAMENTO: { value: 1, label: 'Em Tratamento' },
  FINALIZADO: { value: 2, label: 'Finalizado' },
  REABERTO: { value: 3, label: 'Reaberto' },
  CANCELADO: { value: 4, label: 'Cancelado pelo Paciente' },
  CANCELADO_AUTOMATICAMENTE: { value: 5, label: 'Cancelado pelo Sistema' },
  ABANDONO: { value: 6, label: 'Abandono de Tratamento' },
}

export const encaminhamentoOptions = {
  APROVADO: { value: 0, label: 'Tratamento aprovado' },
  PROFISSIONAL_REJEITOU: { value: 1, label: 'Profissional rejeitou o tratamento' },
  PACIENTE_REJEITOU: { value: 2, label: 'Paciente rejeitou o tratamento' },
}

export const status = Object.values(statusProcedimento)

export const statusPlanoTratamento = Object.values(statusPlano)

export const statusProteses = Object.values(statusProtese)

export const acessos = Object.values(tiposAcesso)

export const formasDePagamento = {
  DINHEIRO: { value: 0, label: 'Dinheiro' },
  CARTAO_DEBITO: { value: 1, label: 'Cartão de Débito' },
  CARTAO_CREDITO: { value: 2, label: 'Cartão de Crédito' },
  BOLETO: { value: 3, label: 'Boleto' },
  CHEQUE: { value: 4, label: 'Cheque' },
  PIX: { value: 5, label: 'Pix' },
}

export const filtroNaoSimAmbos = {
  NAO: { value: 0, label: 'Não' },
  SIM: { value: 1, label: 'Sim' },
  AMBOS: { value: 2, label: 'Ambos' },
}

export const statusProtetico = {
  ATIVO: { value: 0, label: 'Ativo' },
  PENDENTE: { value: 1, label: 'Pendente' },
  INATIVO: { value: 2, label: 'Inativo' },
}

export const statusParcelas = {
  PAGA: { value: 0, label: 'Paga' },
  CANCELADA: { value: 1, label: 'Cancelada' },
}

export const statusPagamentosParcelas = {
  ABERTA: { value: 0, label: 'Em aberto' },
  PAGA: { value: 1, label: 'Paga' },
  CANCELADA: { value: 2, label: 'Cancelada' },
}

export const getParcelaObject = (valor, numero, dataVencimento, status) => ({
  valor,
  numero,
  dataVencimento,
  status,
})

export const estadoCivil = {
  SOLTEIRO: { value: 0, label: 'Solteiro' },
  CASADO: { value: 1, label: 'Casado' },
  SEPARADO: { value: 2, label: 'Separado' },
  DIVORCIADO: { value: 3, label: 'Divorciado' },
  VIUVO: { value: 4, label: 'Viúvo' }
}

export const estadoCivilOptions = Object.values(estadoCivil)