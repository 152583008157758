import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { toast } from 'react-toastify'

import ortodontiaService from '../../services/ortodontia.service'
import PacienteService from '../../services/paciente.service'
import MenuContext from '../../contexts/MenuContexts'
import PageLoading from '../Common/PageLoading'
import PacienteForm from './PacienteForm'
import PacientePlanosTratamento from './PacientePlanosTratamento'
import PacientePlanosOrtodontia from './PacientePlanosOrtodontia'
import AvaliacoesPendentes from './AvaliacoesPendentes'
import PagamentosRealizados from './PagamentosRealizados'
import HistoricoAgendamentos from './HistoricoAgendamentos'
import TabDocumentos from './Documentos/TabDocumentos'

const PacienteDetails = (props) => {
  const acoesPermitidas = {}
  const TabsConstants = {
    dados: 'Dados Pessoais',
    planos: 'Planos de Tratamento',
    planosOrtodontia: 'Planos Ortodônticos',
    pagamentos: 'Pagamentos',
    avaliacoesPendentes: 'Avaliações Pendentes',
    agendamento: 'Agendamentos',
    documentos: 'Documentos',
  }

  const { id } = props.match.params;
  const pacienteId = props.pacienteId || id;

  const { pacienteNome, cpfPaciente, pacienteTelefones, agendamentoId, nomeColaborador, historicoTelefones } = props
  const INITIAL_PACIENTE = { nome: pacienteNome, cpf: cpfPaciente, telefones: pacienteTelefones, agendamentoId, historicoTelefones }

  const [isLoading, setIsLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(TabsConstants.dados)
  const [paciente, setPaciente] = useState({ ...INITIAL_PACIENTE })
  const [planosTratamentoList, setPlanosTratamentoList] = useState([])
  const [planosTratamentoOrtodontiaList, setPlanosTratamentoOrtodontiaList] = useState([])
  const [avaliacoesPendentesList, setAvaliacoesPendentesList] = useState([])
  const [pagamentosRealizados, setPagamentosRealizados] = useState([])

  useEffect(() => {
    didMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const didMount = async () => {
    if (pacienteId) {
      setIsLoading(true)
      await Promise.all([
        getPaciente(),
        getPlanosTratamentoList(),
        getPlanosTratamentoOrtodontiaList(),
        getAvaliacoesPendentesList(),
        getPagamentosRealizados(),
      ])
      setIsLoading(false)
    }
  }

  const getPaciente = async () => {
    try {
      const { data } = await PacienteService.getPacienteById(pacienteId)
      setPaciente(data)
    } catch (e) { }
  }

  const getPlanosTratamentoList = async () => {
    try {
      const trazerDependentes = true
      const { data } = await PacienteService.getPlanosDeTratamento(pacienteId, trazerDependentes)
      setPlanosTratamentoList(data)
    } catch (e) { }
  }

  const getPlanosTratamentoOrtodontiaList = async () => {
    try {
      const { data } = await ortodontiaService.getPlanosTratamentoByPaciente(pacienteId)
      setPlanosTratamentoOrtodontiaList(data)
    } catch (e) { }
  }

  const getAvaliacoesPendentesList = async () => {
    try {
      const { data } = await PacienteService.getAllAvaliacoesPendentes(pacienteId)
      setAvaliacoesPendentesList(data)
      if (data.length === 0) {
        setSelectedTab(TabsConstants.dados)
      }
    } catch (e) { }
  }

  const getPagamentosRealizados = async () => {
    try {
      const { data } = await PacienteService.getPagamentos(pacienteId)
      setPagamentosRealizados(data)
    } catch (e) { }
  }

  const refreshPlanosTratamentoOrtodontiaList = async () => {
    setIsLoading(true)
    try {
      const { data } = await ortodontiaService.getPlanosTratamentoByPaciente(pacienteId)
      setPlanosTratamentoOrtodontiaList(data)
    } catch (e) { }
    setIsLoading(false)
  }

  const salvarPaciente = async (editedPaciente) => {
    setIsLoading(true)
    try {
      if (!editedPaciente.id) {
        await PacienteService.criarPaciente(editedPaciente, editedPaciente.enderecos[0].comprovanteResidencia)
      } else {
        await PacienteService.salvarPaciente(editedPaciente, editedPaciente.enderecos[0].comprovanteResidencia)
      }
      toast('Paciente salvo com sucesso.', { type: toast.TYPE.SUCCESS })
      props.history.push('/pacientes')
    } catch (error) { }
    setIsLoading(false)
  }

  const handleSelectedTab = async (tab) => {
    setIsLoading(true)
    if (tab === TabsConstants.pagamentos) {
      await getPagamentosRealizados()
    }
    setSelectedTab(tab)
    setIsLoading(false)
  }

  const handleCancel = () => {
    props.history.push('/pacientes')
  }

  const renderLoading = () => <PageLoading />

  const renderTabs = () => {
    const PacienteFormProps = { paciente, salvar: salvarPaciente, handleCancel }
    if (!pacienteId) {
      return <PacienteForm {...PacienteFormProps} />
    } else {
      return (
        <Tabs activeKey={selectedTab} onSelect={(tab) => handleSelectedTab(tab)}>
          <Tab eventKey={TabsConstants.dados} title={TabsConstants.dados}>
            <PacienteForm {...PacienteFormProps} />
          </Tab>
          <Tab eventKey={TabsConstants.planos} title={TabsConstants.planos}>
            <PacientePlanosTratamento
              paciente={paciente}
              nomeColaborador={nomeColaborador}
              planosTratamentoList={planosTratamentoList}
              getPlanosTratamentoList={getPlanosTratamentoList}
              getPagamentosRealizados={getPagamentosRealizados}
            />
          </Tab>
          <Tab eventKey={TabsConstants.planosOrtodontia} title={TabsConstants.planosOrtodontia}>
            <PacientePlanosOrtodontia
              paciente={paciente}
              nomeColaborador={nomeColaborador}
              planosTratamentoList={planosTratamentoOrtodontiaList}
              refreshPlanosTratamentoOrtodontiaList={refreshPlanosTratamentoOrtodontiaList}
              getPagamentosRealizados={getPagamentosRealizados}
            />
          </Tab>
          <Tab eventKey={TabsConstants.pagamentos} title={TabsConstants.pagamentos}>
            <PagamentosRealizados
              setIsLoading={setIsLoading}
              pagamentosRealizados={pagamentosRealizados}
              paciente={paciente}
              getPagamentosRealizados={getPagamentosRealizados}
            />
          </Tab>
          <Tab eventKey={TabsConstants.agendamento} title={TabsConstants.agendamento}>
            <HistoricoAgendamentos setIsLoading={setIsLoading} pacienteId={pacienteId} />
          </Tab>
          {avaliacoesPendentesList.length > 0 && (
            <Tab eventKey={TabsConstants.avaliacoesPendentes} title={TabsConstants.avaliacoesPendentes}>
              <AvaliacoesPendentes
                setIsLoading={setIsLoading}
                avaliacoesPendentesList={avaliacoesPendentesList}
                getAvaliacoesPendentesList={getAvaliacoesPendentesList}
                paciente={paciente}
                {...props}
              />
            </Tab>
          )}
          <Tab eventKey={TabsConstants.documentos} title={TabsConstants.documentos}>
            <TabDocumentos setIsLoading={setIsLoading} pacienteId={pacienteId} planosOrtodontia={planosTratamentoOrtodontiaList} planosTratamento={planosTratamentoList} />
          </Tab>
        </Tabs>
      )
    }
  }

  return (
    <MenuContext.Consumer>
      {(acoes) => {
        acoes.map((a) => {
          acoesPermitidas[`${a.nome}`] = a
          return true
        })
        return (
          <div>
            <h1 className='h3 mb-4 text-secondary font-weight-bold'>
              {pacienteId ? 'Detalhes do' : 'Cadastrar'} Paciente
            </h1>
            {renderTabs()}
            {isLoading && renderLoading()}
          </div>
        )
      }}
    </MenuContext.Consumer>
  )
}

export default PacienteDetails
