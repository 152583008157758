import React, { Fragment, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'

import { formatMoney } from '../../../../utils/string.utils'
import CollapsePanel from '../../../Common/CollapsePanel'
import ParcelasList from './ParcelasList'
import { formasDePagamento } from '../../../../constants/common.constants'
import { toast } from 'react-toastify'

export default function RealizarPagamento({
  parcelamentoPagamento,
  selectedParcelas,
  setSelectedParcelas,
  setFormaPagamento,
  sumSelectedParcelasCalculado,
  handleGoBack,
  nextStep,
}) {
  const INITIAL_OPEN_STATE = { step0: true }
  const [stepIsOpen, setStepIsOpen] = useState({ ...INITIAL_OPEN_STATE })
  const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(null)
  const [formasPagamentoSelecionadas, setFormasPagamentoSelecionadas] = useState([])


  const panels = parcelamentoPagamento
    ? [
      ...Object.values(parcelamentoPagamento.plano),
      ...Object.values(parcelamentoPagamento.aparelho),
      ...Object.values(parcelamentoPagamento.complementar),
      ...Object.values(parcelamentoPagamento.suprimentos),
    ]
    : []

  const toggleIsOpen = (step) => {
    let newSteps = { ...INITIAL_OPEN_STATE }
    Object.keys(panels).forEach((_, i) => {
      newSteps[`step${i}`] = false
    })
    setStepIsOpen({
      ...newSteps,
      [step]: !stepIsOpen[step],
    })
  }

  const handleAddFormaPagamento = () => {
    setFormasPagamentoSelecionadas([
      ...formasPagamentoSelecionadas,
      { formaPagamento: null, valor: '' },
    ])
  }

  const handleChangeFormaPagamento = (index, formaPagamento) => {
    const novasFormas = [...formasPagamentoSelecionadas]
    novasFormas[index].formaPagamento = formaPagamento
    setSelectedFormaPagamento(novasFormas)
    setFormaPagamento(formaPagamento)
  }

  const handleChangeValor = (index, valor) => {
    const novasFormas = [...formasPagamentoSelecionadas]
    novasFormas[index].valor = valor
    setFormasPagamentoSelecionadas(novasFormas)
  }

  const handleRemoveFormaPagamento = (index) => {
    const novasFormas = [...formasPagamentoSelecionadas]
    novasFormas.splice(index, 1)
    setFormasPagamentoSelecionadas(novasFormas)
  }

  const handleNextStep = () => {
    const totalDistribuido = formasPagamentoSelecionadas.reduce((acc, curr) => acc + parseFloat(curr.valor || 0), 0)

    if (formasPagamentoSelecionadas.length === 0) {
      toast('Escolha pelo menos uma forma de pagamento', { type: toast.TYPE.WARNING })
      return
    }

    if (parseFloat(totalDistribuido).toFixed(2) !== parseFloat(sumSelectedParcelasCalculado).toFixed(2)) {
      toast('O total distribuído entre as formas de pagamento deve ser igual ao total selecionado', {
        type: toast.TYPE.ERROR,
      })
      return
    }

    setFormaPagamento({formasPagamentoSelecionadas, parcelas: selectedParcelas})
    nextStep()
  }

  const renderPanels = () => (
    <Fragment>
      {panels.map((parcelas, index) => {
        const { nomeAparelho, nomeAparelhoComplementar, descricaoSuprimento } = parcelas[0]
        const text = nomeAparelho || nomeAparelhoComplementar || descricaoSuprimento || 'Plano Ortodôntico'
        return (
          <CollapsePanel
            key={index}
            stepCode={`step${index}`}
            isOpen={stepIsOpen[`step${index}`]}
            toggleIsOpen={toggleIsOpen}
            text={text}
          >
            <ParcelasList
              text={text}
              parcelas={parcelas}
              selectedParcelas={selectedParcelas}
              setSelectedParcelas={setSelectedParcelas}
              panelIndex={index}
            />
          </CollapsePanel>
        )
      })}
    </Fragment>
  )

  return (
    <Fragment>
      <h3 className='my-4 text-secondary font-weight-bold'>Efetuar pagamento</h3>

      {panels.length > 0 ? renderPanels() : <div>Nenhuma parcela em aberto.</div>}



      {selectedParcelas.length > 0 && (
        <div className='pb-5 pt-3'>
          <h1 className='h4 m-0 text-secondary font-weight-bold flex-fill'>Total: {formatMoney(sumSelectedParcelasCalculado)}</h1>

          {formasPagamentoSelecionadas.map((item, index) => (
            <div key={index} className='d-flex align-items-center my-2'>
              <div className='flex-fill'>
                <Select
                  value={item.formaPagamento}
                  onChange={(e) => handleChangeFormaPagamento(index, e)}
                  options={Object.values(formasDePagamento)}
                  placeholder='Forma de pagamento'
                  noOptionsMessage={() => 'Nenhuma forma de pagamento disponível'}
                />
              </div>
              <div className='mx-2'>
                <InputGroup>
                  <FormControl
                    type='number'
                    value={item.valor}
                    onChange={(e) => handleChangeValor(index, e.target.value)}
                    placeholder='Valor'
                  />
                </InputGroup>
              </div>
              <Button variant='danger' onClick={() => handleRemoveFormaPagamento(index)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          ))}

          <Button variant='outline-primary' className='mt-2' onClick={handleAddFormaPagamento}>
            <FontAwesomeIcon icon={faPlus} /> Adicionar forma de pagamento
          </Button>

          <div className='d-flex justify-content-end mt-3'>
            <Button variant='primary' disabled={selectedParcelas.length < 1} onClick={handleNextStep}>
              Efetuar Pagamento
            </Button>
          </div>
        </div>
      )}


      <div style={backContainerStyle} onClick={handleGoBack}>
        <Button variant='outline-primary' style={backStyle}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <span className='text-primary'>Voltar</span>
      </div>
    </Fragment>
  )
}

const backContainerStyle = {
  cursor: 'pointer',
  width: 'fit-content',
}

const backStyle = {
  borderRadius: '100em',
  fontSize: '1.5em',
  padding: '0 6px',
  marginRight: '10px',
}
