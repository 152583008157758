import React, { Fragment, useState } from 'react'
import Swal from 'sweetalert2'
import StepWizard from 'react-step-wizard'

import { formatMoney } from '../../../../utils/string.utils'
import OrtodontiaService from '../../../../services/ortodontia.service'
import Pagamento from './Pagamento'
import Resumo from './Resumo'
import Impressao from './Impressao'
import { printPaymentReceipt } from '../../../Templates/PrintableReceipt'
import { formasDePagamento } from '../../../../constants/common.constants'

export default function RealizarPagamento({
  firstStep,
  setIsLoading,
  parcelamentoPagamento,
  refreshPlanosTratamentoOrtodontiaList,
  getPagamentosRealizados,
}) {
  const [selectedParcelas, setSelectedParcelas] = useState([])
  const [parcelasPayload, setParcelasPayload] = useState([])
  const [wizardRef, setWizardRef] = useState(null)
  const [paymentRecord, setPaymentRecord] = useState(null)
  const [formaPagamento, setFormaPagamento] = useState(null)

  const handleGoBack = () => {
    firstStep()
    wizardRef.firstStep()
    setSelectedParcelas([])
    setPaymentRecord(null)
    refreshPlanosTratamentoOrtodontiaList()
    getPagamentosRealizados()
  }

  const getSumSelectedParcelas = () => {
    let sum = 0
    selectedParcelas.forEach((parcela) => {
      sum += parcela.valor
    })
    return sum
  }

  const getSumSelectedParcelasCalculado = () => {
    let sum = 0
    selectedParcelas.forEach((parcela) => {
      if (parcela.planoTratamentoOrtodontiaPagamentoParcelaId) {
        sum += parcela.valorCalculado
      } else {
        sum += parcela.valor
      }
    })
    return sum
  }

  const getSumSelectedParcelasCalculadoMinusCredito = () => {
    let sum = getSumSelectedParcelasCalculado()
    return sum
  }

  const getDescontoParcelasSelecioandas = () => {
    let sum = 0
    selectedParcelas.forEach((parcela) => {
      sum += parcela.desconto
    })
    return sum
  }

  const getJurosParcelasSelecioandas = () => {
    let sum = 0
    selectedParcelas.forEach((parcela) => {
      sum += parcela.juros
    })
    return sum
  }

  const getMultaParcelasSelecioandas = () => {
    let sum = 0
    selectedParcelas.forEach((parcela) => {
      sum += parcela.multa
    })
    return sum
  }

  const confirmPassword = async () => {
    const qntdParcelas = selectedParcelas.length === 1 ? '1 parcela' : `${selectedParcelas.length} parcelas`
    const { value } = await Swal.fire({
      title: `Deseja confirmar o pagamento de ${qntdParcelas} no valor total de ${formatMoney(
        getSumSelectedParcelasCalculadoMinusCredito()
      )}?`,
      type: 'question',
      input: 'password',
      inputPlaceholder: 'Digite sua senha',
      inputAttributes: { autocapitalize: 'off', autocorrect: 'off' },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#3085d6',
    })
    return value
  }

  const handleSave = async () => {
    const senha = await confirmPassword()
    if (senha) {
      setIsLoading(true)

      try {
        const { data } = await OrtodontiaService.pagar({
          planoTratamentoId: selectedParcelas?.[0]?.planoTratamentoOrtodontiaId,
          pacienteId: selectedParcelas?.[0]?.pacienteId,
          senha: senha,
          pagamentos: formaPagamento.formasPagamentoSelecionadas?.map(forma => {
            return {
              formaPagamento: forma?.formaPagamento?.value,
              valor: parseFloat(forma?.valor)
            }
          }),
          parcelas: formaPagamento?.parcelas?.map(parcela => {
            if(parcela.planoTratamentoOrtodontiaPagamentoParcelaId)
              return parcela.planoTratamentoOrtodontiaPagamentoParcelaId;

            if(parcela.planoTratamentoPagamentoAparelhoComplementarParcelaId)
              return parcela.planoTratamentoPagamentoAparelhoComplementarParcelaId;

            if(parcela.planoTratamentoPagamentoAparelhoParcelaId)
              return parcela.planoTratamentoPagamentoAparelhoParcelaId;

            if(parcela.planoTratamentoPagamentoSuprimentoParcelaId)
              return parcela.planoTratamentoPagamentoSuprimentoParcelaId;
          }),
        })

        wizardRef.nextStep()
        setParcelasPayload(selectedParcelas)
        setPaymentRecord(data.pagamentoOrtodontia)
      } catch (e) {
        console.error(e)
      }
      setIsLoading(false)
    }
  }

  const handlePrint = () => {
    const pagamentoObj = Object.values(formasDePagamento).find(({ value }) => value === paymentRecord.formaPagamento)
    printPaymentReceipt({
      ...paymentRecord,
      parcelasPayload,
      nomeContratante: paymentRecord.paciente.nome,
      formasPagamentoSelecionadas: formaPagamento?.formasPagamentoSelecionadas,
      nomeColaborador: typeof paymentRecord.colaborador !== "undefined" && paymentRecord.colaborador !== null ? paymentRecord.colaborador.nome : "",
      formaPagamento: pagamentoObj ? pagamentoObj.label : formasDePagamento.DINHEIRO.label,
    })
    if (!paymentRecord.reimpressao) {
      OrtodontiaService.marcarImpresso(paymentRecord.id)
    }
  }

  return (
    <Fragment>
      <StepWizard isLazyMount instance={setWizardRef}>
        <Pagamento
          parcelamentoPagamento={parcelamentoPagamento}
          selectedParcelas={selectedParcelas}
          setSelectedParcelas={setSelectedParcelas}
          sumSelectedParcelasCalculado={getSumSelectedParcelasCalculado()}
          handleGoBack={handleGoBack}
          setFormaPagamento={setFormaPagamento}
        />
        <Resumo
          handleSave={handleSave}
          total={getSumSelectedParcelas()}
          totalCalculado={getSumSelectedParcelasCalculadoMinusCredito()}
          desconto={getDescontoParcelasSelecioandas()}
          juros={getJurosParcelasSelecioandas()}
          multa={getMultaParcelasSelecioandas()}
          formaPagamento={formaPagamento}
        />
        <Impressao handlePrint={handlePrint} handleGoBack={handleGoBack} />
      </StepWizard>
    </Fragment>
  )
}
