import React, { useState } from 'react'
import StepWizard from 'react-step-wizard'
import Swal from 'sweetalert2'
import { Col, Row } from 'react-bootstrap'

import { printPaymentReceipt } from '../../../Templates/PrintableReceipt'
import { formatMoney } from '../../../../utils/string.utils'
import financeiroService from '../../../../services/financeiro.service'

import Pagamento from './Pagamento'
import Resumo from './Resumo'
import Impressao from './Impressao'
import { formasDePagamento } from '../../../../constants/common.constants'

const RealizarPagamento = ({
  nomeColaborador,
  paciente,
  parcelas,
  setIsLoading,
  firstStep,
  getParcelas,
  planoTratamento,
}) => {
  const [paymentRecord, setPaymentRecord] = useState(null)
  const [formaPagamento, setFormaPagamento] = useState(null)
  const [selectedParcelas, setSelectedParcelas] = useState([])
  const [parcelasPayload, setParcelasPayload] = useState([])

  const handleEfetuarPagamento = async () => {
    const qntdParcelas = selectedParcelas.length === 1 ? '1 parcela' : `${selectedParcelas.length} parcelas`
    const { value: password } = await Swal.fire({
      title: `Deseja confirmar o pagamento de ${qntdParcelas} no valor total de ${getSomaCalculadaParcelasSelecioandas()}?`,
      type: 'question',
      input: 'password',
      inputPlaceholder: 'Digite sua senha',
      inputAttributes: { autocapitalize: 'off', autocorrect: 'off' },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#3085d6',
    })

    if (password) {
      setIsLoading(true)
      try {
        const parcelasPayload = selectedParcelas.map((parcelaId) => ({
          ...parcelas.find(
            (parcela) =>
              parcelaId === parcela.planoTratamentoPagamentoParcelaId ||
              parcelaId === parcela.planoTratamentoPagamentoProteticoParcelaId
          ),
        }))

        const { data } = await financeiroService.pagar({
          pagamentos: formaPagamento.formasPagamentoSelecionadas?.map(forma => {
            return {
              formaPagamento: forma?.formaPagamento?.value,
              valor: parseFloat(forma?.valor)
            }
          }),
          parcelas: formaPagamento.parcelas,
          planoTratamentoId: planoTratamento.id,
          pacienteId: planoTratamento.pacienteId,
          senha: password
        })

        setPaymentRecord(data)
        setParcelasPayload(parcelasPayload)
        setSelectedParcelas([])
        setIsLoading(false)
        return true
      } catch (e) {
        setIsLoading(false)
        return false
      }
    }
    setIsLoading(false)
    return false
  }

  const selecionarTodasParcelas = () => {
    const todasSelecionadas = selectedParcelas.length > 0 && selectedParcelas.length === parcelas.length
    if (todasSelecionadas) {
      setSelectedParcelas([])
    } else {
      setSelectedParcelas(parcelas.map((parcela) => parcela.planoTratamentoPagamentoParcelaId))
    }
  }

  const toggleParcela = (parcelaId, index, planoTratamentoPagamentoId) => {
    let newSelectedParcelas = [...selectedParcelas]
    const parcelasFiltradas = parcelas.filter(
      (parcela) => parcela.planoTratamentoPagamentoId === planoTratamentoPagamentoId
    )

    if (selectedParcelas.includes(parcelaId)) {
      const parcelasToRemove = [parcelaId]
      parcelasFiltradas.forEach((parc, i) => {
        if (i > index) {
          const { planoTratamentoPagamentoProteticoParcelaId, planoTratamentoPagamentoParcelaId } = parc
          const id = planoTratamentoPagamentoProteticoParcelaId || planoTratamentoPagamentoParcelaId
          parcelasToRemove.push(id)
        }
      })
      newSelectedParcelas = newSelectedParcelas.filter((id) => !parcelasToRemove.includes(id))
      setSelectedParcelas(newSelectedParcelas)
    } else {
      newSelectedParcelas.push(parcelaId)
      parcelasFiltradas.forEach((parc, i) => {
        if (i < index) {
          const { planoTratamentoPagamentoProteticoParcelaId, planoTratamentoPagamentoParcelaId } = parc
          const id = planoTratamentoPagamentoProteticoParcelaId || planoTratamentoPagamentoParcelaId

          if (!newSelectedParcelas.includes(id)) {
            newSelectedParcelas.push(id)
          }
        }
      })
      setSelectedParcelas(newSelectedParcelas)
    }
  }

  const handlePrint = () => {
    const pagamentoObj = Object.values(formasDePagamento).find(
      ({ value }) => value === paymentRecord.pagamento.formaPagamento
    )

    printPaymentReceipt({
      ...paymentRecord.pagamento,
      nomeContratante: paciente.nome,
      nomeColaborador,
      parcelasPayload,
      formasPagamentoSelecionadas: formaPagamento?.formasPagamentoSelecionadas,
      formaPagamento: pagamentoObj ? pagamentoObj.label : formasDePagamento.DINHEIRO.label,
    })
    if (!paymentRecord.pagamento.reimpressao) {
      financeiroService.marcarImpresso(paymentRecord.pagamento.id)
    }
  }

  function getValueParcelasSelecionadas(parcelas, selectedParcelas, key) {
    let result = 0
    if (parcelas && parcelas.length > 0) {
      parcelas.forEach((p) => {
        if (
          selectedParcelas.includes(p.planoTratamentoPagamentoParcelaId) ||
          selectedParcelas.includes(p.planoTratamentoPagamentoProteticoParcelaId)
        ) {
          result += p[key]
        }
      })
    }
    return result
  }

  const getSomaParcelasSelecioandas = () => {
    let result = getValueParcelasSelecionadas(parcelas, selectedParcelas, 'valor')
    return formatMoney(result)
  }

  const getSomaCalculadaParcelasSelecioandas = () => {
    const result = getValueParcelasSelecionadas(parcelas, selectedParcelas, 'valorCalculado')
    return formatMoney(result);
  }

  const getJurosParcelasSelecioandas = () => {
    let result = getValueParcelasSelecionadas(parcelas, selectedParcelas, 'juros')
    return formatMoney(result)
  }

  const getMultaParcelasSelecioandas = () => {
    let result = getValueParcelasSelecionadas(parcelas, selectedParcelas, 'multa')
    return formatMoney(result)
  }

  const getDescontoParcelasSelecioandas = () => {
    let result = getValueParcelasSelecionadas(parcelas, selectedParcelas, 'desconto')
    return formatMoney(result)
  }

  const clearPagamento = async () => {
    setPaymentRecord(null)
    setSelectedParcelas([])
    try {
      await getParcelas()
    } catch (error) { }
  }

  const handleVoltar = (p) => {
    if (p.activeStep === 3) {
      clearPagamento()
    }
    firstStep()
  }

  return (
    <div className='mb-5 pb-5 px-3'>
      <Row>
        <Col>
          <StepWizard isLazyMount>
            <Pagamento
              getSomaParcelasSelecioandas={getSomaCalculadaParcelasSelecioandas}
              totalCalculado={getValueParcelasSelecionadas(parcelas, selectedParcelas, 'valorCalculado')}
              parcelas={parcelas}
              setFormaPagamento={setFormaPagamento}
              selecionarTodasParcelas={selecionarTodasParcelas}
              selectedParcelas={selectedParcelas}
              toggleParcela={toggleParcela}
              handleVoltar={handleVoltar}
            />
            <Resumo
              formaPagamento={formaPagamento}
              handleEfetuarPagamento={handleEfetuarPagamento}
              total={getSomaParcelasSelecioandas()}
              totalCalculado={getSomaCalculadaParcelasSelecioandas()}
              desconto={getDescontoParcelasSelecioandas()}
              juros={getJurosParcelasSelecioandas()}
              multa={getMultaParcelasSelecioandas()}
            />
            <Impressao
              paymentRecord={paymentRecord}
              handlePrint={handlePrint}
              onBack={firstStep}
              setIsLoading={setIsLoading}
              planoTratamento={planoTratamento}
            />
          </StepWizard>
        </Col>
      </Row>
    </div>
  )
}

export default RealizarPagamento
